import React from 'react';
import { AnimatedBackground } from 'animated-backgrounds';
function Background() {
    return (
        <div>
            <AnimatedBackground animationName="gradientWave" />
        </div>
    );
}
export default Background;


// auroraBorealis also good